import { Card } from "react-bootstrap";
import SearchButton from "../buttons/SearchButton";

import "./cards.scss";

function BookingPreviewCard(props) {
    return (
        <Card className="booking-preview-card" onClick={props.onClick}>
            <Card.Body className="card-body">
                <div className="row align-items-center justify-content-between">
                    <div className="col-md-8">
                        <div className="row gy-4">
                            <div className="col-md-3" onClick={props.onClickPreview}>
                                <img className="preview-image" src="https://a.hwstatic.com/image/upload/f_auto,q_auto,t_30/v1/propertyimages/3/304638/ywx5z1ale4vtvahhtf49" width={80} height={80} />
                                <i className="fas fa-images image-gallery-icon"></i>
                            </div>
                            <div className="col-md-8 text-center text-md-start">
                                <p className="type">{props.type}</p>
                                <p className="price">{props.dailyCharge}</p>
                                <p className="price">{props.total}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-auto text-center text-md-end pt-4 pt-md-0">
                        <SearchButton text='Choose' onClick={props.onNext} />
                    </div>
                </div>
            </Card.Body>
        </Card>
    );
}

export default BookingPreviewCard;
