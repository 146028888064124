import { Card, Row, Col } from "react-bootstrap";

function TestimonialCard({ imgSrc, name, position }) {
  return (
    <Card className="testimonial-card">
      <Card.Body className="d-flex align-items-center">
        <Row className="align-items-center">
          <Col>
            <img width="175px" src={imgSrc} alt={name} />
          </Col>
          <Col>
            <p className="name">{name}</p>
            <p className="position">{position}</p>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}

export default TestimonialCard;
