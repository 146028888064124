import { useContext, useEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { AuthContext } from './services/context/AuthContext'
import { getAccessToken } from './services/sessionService';
import { GlobalContext } from './services/context/ContextProvider';
import { FETCHING } from './services/constants';

import PrivateRoute from './components/routes/PrivateRoute';
import PublicRoute from './components/routes/PublicRoute';

import Home from "./modules/home/Home";
import DormitoryBooking from "./modules/booking/DormitoryBooking";
import MyBookings from './modules/myBookings/MyBookings';
import BookingDetails from './modules/bookingDetails/BookingDetails';
import Login from './modules/login/Login';

import './App.scss'
import "./global.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-datepicker/dist/react-datepicker.css";
import 'react-toastify/dist/ReactToastify.css';
import PrivacyPolicy from './modules/privacyPolicy/PrivacyPolicy';
import TermsAndCondition from './modules/termsAndCondition/TermsAndCondition';

function App() {
  const { globalState } = useContext(GlobalContext)
  const { auth } = globalState

  const [existingTokenData, setExistingTokenData] = useState({})
  const [authToken, setAuthTokens] = useState(existingTokenData.accessToken)
  const setTokens = (data) => {
    localStorage.setItem('tokens', JSON.stringify(data))
    setAuthTokens(data)
  }

  useEffect(() => {
    const value = getAccessToken()
    setExistingTokenData(value)
    setAuthTokens(value.accessToken)
  }, [auth.isLoggedIn])

  if (existingTokenData.status === FETCHING || !existingTokenData.status) {
    return (<div className='loading-spinner'>Loading</div>)
  }
  return (
    <AuthContext.Provider
      value={{
        authToken,
        userId: existingTokenData.userId,
        setAuthTokens: setTokens
      }}>
      <div className="App">
        <ToastContainer
          position="bottom-left"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme='dark'
          style={{ textAlign: 'left' }}
        />
        <Routes>
          <Route path="/login" element={
            <PublicRoute redirectTo="/dormitory-booking">
              <Login />
            </PublicRoute>}
          />
          <Route path="/booking/:id" element={
            <PrivateRoute redirectTo="/dormitory-booking">
              <BookingDetails />
            </PrivateRoute>}
          />
          <Route path="/my-bookings" element={
            <PrivateRoute redirectTo="/dormitory-booking">
              <MyBookings />
            </PrivateRoute>}
          />
          <Route path="/dormitory-booking" element={<DormitoryBooking />} />
          <Route path="/" element={<Home />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-and-conditions" element={<TermsAndCondition />} />
        </Routes>
      </div>
    </AuthContext.Provider >
  );
}

export default App;
