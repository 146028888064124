import { AUTH_LOGOUT, LOGIN_ERROR, LOGIN_FETCHING, LOGIN_SUCCESS, REFRESH_TOKEN, RESET_AUTH_STATUS, SET_LOGGED_IN, SIGNUP_ERROR, SIGNUP_FETCHING, SIGNUP_SUCCESS } from "../actionTypes"

export const loginFetch = () => ({ type: LOGIN_FETCHING })
export const loginSuccess = data => ({ type: LOGIN_SUCCESS, data })
export const loginError = data => ({ type: LOGIN_ERROR, data })

export const signupFetch = () => ({ type: SIGNUP_FETCHING })
export const signupSuccess = data => ({ type: SIGNUP_SUCCESS, data })
export const signupError = data => ({ type: SIGNUP_ERROR, data })

export const setLoggedIn = data => ({ type: SET_LOGGED_IN, data })
export const authLogout = () => ({ type: AUTH_LOGOUT })
export const refreshToken = () => ({ type: REFRESH_TOKEN })
export const resetAuthStatus = () => ({ type: RESET_AUTH_STATUS })
