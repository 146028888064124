import BackButton from "../../components/buttons/BackButton"
import SearchButton from "../../components/buttons/SearchButton"

function BookingPreview(props) {
    const checkInDate = new Date(props.searchData.checkIn).toDateString()
    const checkOutDate = new Date(props.searchData.checkOut).toDateString()
    const checkInTime = new Date(props.searchData.checkIn).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    const checkOutTime = new Date(props.searchData.checkOut).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    return (
        <div className="booking-preview text-start">
            <p className="title">Your booking details</p>
            <div className="row gy-3">
                <div className="col-md-6">
                    <p className="date-label">Check-in</p>
                    <p className="date">{checkInDate}</p>
                    <p className="time">{checkInTime}</p>
                </div>
                <div className="col-md-6">
                    <p className="date-label">Check-in</p>
                    <p className="date">{checkOutDate}</p>
                    <p className="time">{checkOutTime}</p>
                </div>
            </div>
            <hr></hr>
            <div>
                <p className="sub-heading">Total length of stay:</p>
                <p className="caption">{props.availabilityData.days_count} night</p>
            </div>
            <hr></hr>
            <div>
                <p className="sub-heading">You selected:</p>
                <p className="caption">{props.searchData.beds} x Bed in 6-Bed Mixed Dormitory Room</p>
            </div>
            <p className="price my-4">Total price : ₹ {props.availabilityData.total_charge}</p>
            <p className="terms-warning">By clicking Proceed you are agreeing to the <a href="/terms-and-conditions" target="_blank">Terms and Conditions</a> and <a href="/privacy-policy" target="_blank">Privacy Policy</a>.</p>
            <div>
                <SearchButton text="Proceed to pay" onClick={props.onNext} />
            </div>
            <div className="mt-4">
                <BackButton text="Reset search" onClick={props.onClickReset} />
            </div>
        </div>
    )
}

export default BookingPreview