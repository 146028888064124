import Button from 'react-bootstrap/Button'
import './buttons.scss'

function BackButton({ text, onClick, disabled, isLoading }) {
    return (
        <Button className='back-button' onClick={onClick} disabled={isLoading || disabled}>
            {text}
        </Button>
    )
}

export default BackButton