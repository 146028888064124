const API_BASE_URL = 'https://api.csnstadium.org'

export const IDLE = 'IDLE';
export const FETCHING = 'FETCHING';
export const SUCCESS = 'SUCCESS';
export const ERROR = 'ERROR';

export const endPoints = {
  LOGIN: `${API_BASE_URL}/login`,
  SIGNUP: `${API_BASE_URL}/signup`,

  CHECK_AVAILABILITY: `${API_BASE_URL}/check_booking`,
  CONFIRM_BOOKING: `${API_BASE_URL}/confirm_booking`,

  LIST_BOOKINGS: `${API_BASE_URL}/bookings`,
  GET_BOOKING_DETAILS: (bookingId) => `${API_BASE_URL}/bookings/${bookingId}`,
  CANCEL_BOOKING: (bookingId) => `${API_BASE_URL}/bookings/${bookingId}/cancel`
};
