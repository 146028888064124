import { Form } from "react-bootstrap"

function SelectInput(props) {
    return (
        <div className='form-group select-input-container'>
            <label className="label">{props.label}</label>
            <Form.Select name={props.name} className='select-input mt-2' onChange={props.onChange} onBlur={props.onBlur} value={props.value}>
                <option value="">Select</option>
                {props.options.map(item => {
                    return (
                        <option key={item.id} value={item.id}>{item.value}</option>
                    )
                })}
            </Form.Select>
            {props.error && <p className="error text-warning">{props.error}</p>}
        </div>
    )
}

export default SelectInput