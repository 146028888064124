import { Form } from "react-bootstrap";
import './inputs.scss'

function RadioButton(props) {
    return (
      <Form.Check
        className="radio-button mx-2"
        type="radio"
        label={props.label}
        name={props.name}
        onChange={props.onChange}
        onClick={props.onClick}
        value={props.value}
        checked={props.checked}
        defaultChecked={props.defaultChecked}
      />
    );
}

export default RadioButton