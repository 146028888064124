import { useCallback, useContext } from "react"
import axios from "axios"

import { cancelBookingError, cancelBookingFetching, cancelBookingSuccess, checkBookAvailabilityError, checkBookAvailabilityFetching, checkBookAvailabilitySuccess, confirmBookingError, confirmBookingFetching, confirmBookingSuccess, getBookingDetailsError, getBookingDetailsFetching, getBookingDetailsSuccess, listBookingsError, listBookingsFetching, listBookingsSuccess, resetBookingStatus } from "../actions"
import { endPoints } from "../constants"
import { GlobalContext } from "../context/ContextProvider"
import axiosInstance from "../axiosInstance"


export const useBookingService = () => {
    const { dispatch } = useContext(GlobalContext)

    const checkAvailability = useCallback(async (request) => {
        dispatch(checkBookAvailabilityFetching())
        try {
            const { data } = await axios.post(endPoints.CHECK_AVAILABILITY, request)
            dispatch(checkBookAvailabilitySuccess(data))
        } catch (e) {
            dispatch(checkBookAvailabilityError(e))
        }
    }, [dispatch])

    const confirmBooking = useCallback(async (request) => {
        dispatch(confirmBookingFetching())
        try {
            const axios = axiosInstance({ url: endPoints.CONFIRM_BOOKING })
            const { data } = await axios.post('', request)
            dispatch(confirmBookingSuccess(data))
        } catch (e) {
            dispatch(confirmBookingError(e))
        }
    }, [dispatch])

    const listBookings = useCallback(async () => {
        dispatch(listBookingsFetching())
        try {
            const axios = axiosInstance({ url: endPoints.LIST_BOOKINGS })
            const { data } = await axios.get('')
            dispatch(listBookingsSuccess(data.bookings))
        } catch (e) {
            dispatch(listBookingsError(e))
        }
    }, [dispatch])

    const getBookingDetails = useCallback(async (bookingId) => {
        dispatch(getBookingDetailsFetching())
        try {
            const axios = axiosInstance({ url: endPoints.GET_BOOKING_DETAILS(bookingId) })
            const { data } = await axios.get('')
            dispatch(getBookingDetailsSuccess(data.booking))
        } catch (e) {
            dispatch(getBookingDetailsError(e))
        }
    }, [dispatch])

    const cancelBooking = useCallback(async (bookingId) => {
        dispatch(cancelBookingFetching())
        try {
            const axios = axiosInstance({ url: endPoints.CANCEL_BOOKING(bookingId) })
            const { data } = await axios.post('', {})
            dispatch(cancelBookingSuccess(data))
        } catch (error) {
            dispatch(cancelBookingError(error.response.data))
        }
    }, [dispatch])

    const resetBooking = useCallback(async () => {
        dispatch(resetBookingStatus())
    }, [dispatch])


    return { checkAvailability, confirmBooking, listBookings, getBookingDetails, cancelBooking, resetBooking }
}