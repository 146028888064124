import { Container } from "react-bootstrap";

import Header from "../../components/layout/Header";

function PrivacyPolicy() {
  return (
    <div>
      <Container>
        <Header />
        <div className="text-white mt-5 pt-5">
          <h2 className="text-center fw-bolder">Privacy Policy</h2>
          <p className="text-start mt-5">
            Please ensure that this Privacy Policy is perused by you before
            availing any services from us. This Privacy Policy shall be updated
            from time to time and to stay abreast with our methods of using your
            information and protecting your privacy, please keep reviewing this
            Policy.
          </p>
          <p className="text-start mt-3">
            Integrated Police Sports Complex or Chandrasekharan Nair Stadium is
            registered under co-operative act under Kerala Govt. with its
            registered office at M.G.Road, Palayam, Thiruvananthapuram-695033,
            Kerala, India (“Company”, "We", "Us", "Our" and terms of similar
            meaning) is committed to protecting your privacy. For the purposes
            of this Privacy Policy, the Users, Delivery Partners, Merchants (as
            defined in the Terms of Use) shall together hereinafter be referred
            to as “You”, “Your” or “Yourself”.
          </p>
          <p className="text-start mt-3">
            The Company has adopted this Privacy Policy to set out the manner in
            which personal data and other information is collected, received,
            stored, processed, disclosed, transferred, dealt with and handled by
            the Company in relation to your use of our services through the
            website www.csnstadium.org (“Website”) (collectively referred to as
            the “Platform”) or initiating or undertaking any transaction on the
            Platform. This Privacy Policy does not apply to information that You
            provide to, or that is collected by, any third-party through the
            Platform, and any third-party site that You access or use in
            connection with the Services offered on the Platform.
          </p>
          <p className="text-start mt-3">
            Please read the Privacy Policy carefully before using or registering
            on the Platform or accessing any material, information or availing
            any services (“Services”) through the Platform. By clicking on the
            “I accept” button on the landing page, you accept this Privacy
            Policy and agree to be legally bound by the same.
          </p>
          <p className="text-start mt-3">
            This Privacy Policy is incorporated into and subject to Our Terms of
            Use (“Terms”) and shall be read harmoniously and in conjunction with
            the Terms. In the event of any conflict between the Terms and this
            Privacy Policy, the provisions of the Terms shall supersede the
            Privacy Policy. All capitalized terms not defined under this Privacy
            Policy shall have the meaning ascribed to it under the applicable
            Terms.
          </p>
          <p className="text-start mt-3">
            Please feel free to direct any questions or concerns regarding this
            Privacy Policy by contacting us through this Platform or by writing
            to us at
            <a href="mailto:secretarykpsyws@gmail.com">
              secretarykpsyws@gmail.com
            </a>
            .
          </p>
          <h6 className="text-start mt-3 fw-bold">
            STATEMENT OF PRIVACY PRACTICE
          </h6>
          <p className="text-start mt-3">
            Your information is collected and processed in accordance with the
            terms and conditions of this Privacy Policy.
          </p>
          <h6 className="text-start mt-3 fw-bold">
            INFORMATION COLLECTION AND USAGE
          </h6>
          <p className="text-start mt-3">Information you provide to us</p>
          <p className="text-start mt-3">
            Personally identifiable information: Create or update your Shop
            account, which may include your name, email, phone number, login
            name and password, address, payment or banking information, date of
            birth and profile picture.
          </p>
          <p className="text-start mt-3">
            Provide content to us, which may include reviews, ordering details
            and history, favorite vendors, special merchant requests, contact
            information of people you refer to us and other information you
            provide on the Platform (“Your Content”).
          </p>
          <p className="text-start mt-3">
            The app does use third party services that may collect information
            used to identify you.
          </p>
          <p className="text-start mt-3 fw-bold">Log Data</p>
          <p className="text-start mt-3">
            We want to inform you that whenever you use our Service, in a case
            of an error in the app we collect data and information (through
            third party products) on your phone called Log Data. This Log Data
            may include information such as your device Internet Protocol (“IP”)
            address, device name, operating system version, the configuration of
            the app when utilizing our Service, the time and date of your use of
            the Service, and other statistics.
          </p>
          <p className="text-start mt-3 fw-bold">Cookies</p>
          <ul className="text-start mt-3">
            <li>
              To allow you to participate in interactive features of our
              Services, if any; or
            </li>
            <li>
              To measure or understand the effectiveness of advertising we serve
              to you and others, and to deliver relevant advertising to you.
            </li>
            <li>
              If you are a partner or merchant or delivery partner, to track the
              progress of delivery or status of the order placed by our
              customers.
            </li>
            <li>
              We may share your information with any present or future member of
              our “Group” (as defined below)or affiliates for our internal
              business purposes.
            </li>
          </ul>
          <h6 className="text-start mt-3 fw-bold">OPT-OUT</h6>
          <p className="text-start mt-3">
            When you sign up for an account, you are opting in to receive emails
            from us. You can log in to manage your email preferences or you can
            follow the “unsubscribe” instructions in commercial email messages,
            but note that you cannot opt out of receiving certain administrative
            notices, service notices, or legal notices from us.
          </p>
          <p className="text-start mt-3">
            If you wish to withdraw your consent for the use and disclosure of
            your personal information in the manner provided in this Policy,
            please write to us at secretarykpsyws@gmail.com. Please note that we
            may take time to process such requests, and your request shall take
            effect no later than 7 (Seven) business days from the receipt of
            such request, after which we will not use your personal data for any
            processing unless required by us to comply with our legal
            obligations. We may not be able offer you any or all Services upon
            such withdrawal of your consent.
          </p>
          <h6 className="text-start mt-3 fw-bold">SECURITY</h6>
          <p className="text-start mt-3">
            We value your trust in providing us your Personal Information, thus
            we are striving to use commercially acceptable means of protecting
            it. But remember that no method of transmission over the internet,
            or method of electronic storage is 100% secure and reliable, and we
            cannot guarantee its absolute security.
          </p>
          <h6 className="text-start mt-3 fw-bold">LINKS TO OTHER SITES</h6>
          <p className="text-start mt-3">
            This Service may contain links to other sites. If you click on a
            third-party link, you will be directed to that site. Note that these
            external sites are not operated by us. Therefore, we strongly advise
            you to review the Privacy Policy of these websites. We have no
            control over and assume no responsibility for the content, privacy
            policies, or practices of any third-party sites or services.
          </p>
          <h6 className="text-start mt-3 fw-bold">TRANSPARENCY AND CHOICE</h6>
          <p className="text-start mt-3">
            Your acknowledgement; All information disclosed by you shall be
            deemed to be disclosed willingly and without any coercion. No
            liability pertaining to the authenticity/genuineness/
            misrepresentation/ fraud/ negligence, etc. of the information
            disclosed shall lie on the Company nor will the Company in any way
            be responsible to verify any information obtained from You.
          </p>
          <h6 className="text-start mt-3 fw-bold">CHILDREN’S PRIVACY</h6>
          <p className="text-start mt-3">
            These Services do not address anyone under the age of 18. We do not
            knowingly collect personally identifiable information from children
            under 18. In the case we discover that a child under 18 has provided
            us with personal information, we immediately delete this from our
            servers. If you are a parent or guardian and you are aware that your
            child has provided us with personal information, please contact us
            so that we will be able to do necessary actions.
          </p>
          <h6 className="text-start mt-3 fw-bold">COMPLAINTS</h6>
          <p className="text-start mt-3">
            In case of any dissatisfaction in relation to the Platform, You
            shall first file a formal complaint with the customer service of the
            Company, prior to pursuing any other recourse. The complaints can be
            lodged at{" "}
            <a href="mailto:secretarykpsyws@gmail.com">
              secretarykpsyws@gmail.com
            </a>
            , and upon lodging a complaint You agree to provide complete support
            to the customer service team with such reasonable information as may
            be sought by them from You. The decision of the Company on the
            complaints shall be final and You agree to be bound by the same.
          </p>
          <h6 className="text-start mt-3 fw-bold">
            CHANGES TO THIS PRIVACY POLICY
          </h6>
          <p className="text-start mt-3">
            We may update our Privacy Policy from time to time. Thus, you are
            advised to review this page periodically for any changes. We will
            notify you of any changes by posting the new Privacy Policy on this
            page. These changes are effective immediately after they are posted
            on this page.
          </p>
          <h6 className="text-start mt-3 fw-bold">GRIEVANCES</h6>
          <p className="text-start mt-3">
            In the event You have any grievances relating to the Privacy Policy,
            please inform within 24 hours of occurrence of the instance from
            which the grievance has arisen, by writing an email to the Grievance
            Officer at email{" "}
            <a href="mailto:secretarykpsyws@gmail.com">
              secretarykpsyws@gmail.com
            </a>{" "}
          </p>
        </div>
      </Container>
    </div>
  );
}

export default PrivacyPolicy;
