import { useContext, useEffect, useState } from "react"
import { Container } from "react-bootstrap"
import { toast } from 'react-toastify';
import { isSafari } from "react-device-detect";

import { useAuth } from "../../services/context/AuthContext";
import { GlobalContext } from '../../services/context/ContextProvider'
import { useAuthService, useBookingService } from '../../services/hooks'
import { SUCCESS, ERROR } from '../../services/constants'

import Header from "../../components/layout/Header"
import Paper from "../../components/Paper/Paper"
import AddressForm from "./AddressForm"
import SignupForm from "./SignupForm"
import SearchForm from "./SearchForm"
import SearchResults from "./SearchResults"
import LoginForm from "./LoginForm"
import BookingPreview from "./BookingPreview";
import AuthHeader from "../../components/layout/AuthHeader";
import './DormitoryBooking.scss'

function DormitoryBooking() {
    const { globalState } = useContext(GlobalContext)
    const { booking, auth } = globalState
    const { authToken } = useAuth()

    const { checkAvailability, confirmBooking, resetBooking } = useBookingService()
    const { signup, login, resetAuth } = useAuthService()

    const [stage, setStage] = useState(0)
    const [searchData, setSearchData] = useState()
    const [signupData, setSignupData] = useState()
    const [addressData, setAddressData] = useState()

    useEffect(() => {
        if (stage === 0 && booking.checkAvailability.status === SUCCESS) {
            setStage(1)
            resetBooking()
        } else if (stage === 0 && booking.checkAvailability.status === ERROR) {
            toast.error("Something went wrong! Please try again")
            resetBooking()
        }
    }, [stage, booking.checkAvailability.status, resetBooking])

    useEffect(() => {
        if (stage === 3 && auth.signup.status === SUCCESS) {
            setStage(5)
            resetAuth()
        } else if (stage === 3 && auth.signup.status === ERROR) {
            toast.error(auth.signup.data.error)
            resetAuth()
        }
    }, [stage, auth.signup, resetAuth])

    useEffect(() => {
        if (stage === 4 && auth.login.status === SUCCESS) {
            setStage(5)
            resetAuth()
        } else if (stage === 4 && auth.login.status === ERROR) {
            toast.error(auth.login.data.error)
            resetAuth()
        }
    }, [stage, auth.login, resetAuth])

    useEffect(() => {
        if (stage === 5 && booking.confirmBooking.status === SUCCESS) {
            window.location.href = booking?.confirmBooking?.data?.payment_link
        } else if (stage === 0 && booking.confirmBooking.status === ERROR) {
            toast.error("Something went wrong! Please try again")
            resetAuth()
        }
    }, [stage, booking.confirmBooking, resetAuth])

    const handleNext = (data) => {
        if (stage === 0 && data) {
            setSearchData(data)
            const request = {
                start_ts: data.checkIn,
                end_ts: data.checkOut,
                reservations: parseInt(data.beds),
                user_type: data.for
            }
            checkAvailability(request)
        } else if (stage === 1) {
            if (authToken) {
                setStage(5)
                return
            }
            setStage(2)
        } else if (stage === 2 && data) {
            if (authToken) {
                setStage(5)
                return
            }
            setSignupData(data)
            setStage(3)
        } else if (stage === 3 && data) {
            if (authToken) {
                setStage(5)
                return
            }
            if (searchData.for === 'police' && !data.penNo) {
                toast.error("Please enter PEN Number to continue")
                return
            }
            const request = {
                email: signupData.email,
                password: signupData.password,
                phone: data.phone,
                name: data.firstName + ' ' + data.lastName,
                gender: data.gender,
                user_type: searchData.for,
                pen_no: data.penNo
            }
            signup(request)
            setAddressData(data)
        } else if (stage === 4 && data) {
            if (authToken) {
                setStage(5)
                return
            }
            const request = {
                email: data.email,
                password: data.password
            }
            login(request)
        } else if (stage === 5) {
            const request = {
                start_ts: searchData.checkIn,
                end_ts: searchData.checkOut,
                reservations: parseInt(searchData.beds),
                user_type: searchData.for
            }
            confirmBooking(request)
        }
    }

    return (
        <div className="dormitory-booking">
            <Container>
                {authToken ? <AuthHeader /> : <Header />}
                <div className="form">
                    <Paper>
                        <h1 className="title">{stage === 2 ? 'Create an account!' : stage === 4 ? 'Login' : 'Dormitory Booking'}</h1>
                        {stage === 0 && <SearchForm onNext={handleNext} searchData={searchData} />}
                        {stage === 1 && <SearchResults result={booking.checkAvailability.data} onCancel={() => setStage(0)} onNext={handleNext} />}
                        {stage === 2 && !authToken && <SignupForm onClickLogin={() => setStage(4)} onNext={handleNext} />}
                        {stage === 3 && !authToken && <AddressForm isPolice={searchData.for === 'police'} onNext={handleNext} onBack={() => setStage(2)} />}
                        {stage === 4 && !authToken && <LoginForm onNext={handleNext} onClickSignup={() => setStage(2)} />}
                        {stage === 5 && <BookingPreview searchData={searchData} availabilityData={booking.checkAvailability.data} onNext={handleNext} onClickReset={() => setStage(0)} />}
                    </Paper>
                </div>
            </Container>
            {!isSafari && (
                <div className="gradient-blur">
                    <div className="gradient-yellow"></div>
                    <div className="gradient-blue"></div>
                    <div className="gradient-orange"></div>
                </div>
            )}
        </div>
    )
}

export default DormitoryBooking
