import { Col, Row } from "react-bootstrap";
import Slider from "react-slick";
import { isMobile, isSafari } from "react-device-detect";

import FeaturesCard from "../../components/card/FeaturesCard";

function FeaturesSection() {
  const settings = {
    speed: 1500,
    slidesToShow: 2,
    slidesToScroll: 2,
  };
  const mobSettings = {
    speed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <Row className="features-section" id='features'>
      <Col xs={12}>
        <h2 className="text-center title">What we offer</h2>
        <p className="text-center">
          <div className="sub-title">
            Balanced mind in a balanced body<div className="yellow-dot"></div>
          </div>
        </p>
        <Row className="features-container p-4">
          <Slider {...(isMobile ? mobSettings : settings)}>
            <div>
              <FeaturesCard
                title="Yoga Training"
                description="Morning 06.00 to 08.00 - Evening 18.00 to 20.00"
                imgSrc="/assets/images/yoga-training.jpg"
              />
            </div>
            <Col xs>
              <FeaturesCard
                title="Swimming"
                description="Morning 06.00 to 13.00 - Evening 18.00 to 20.00"
                imgSrc="/assets/images/swimming.jpg"
              />
            </Col>
            <Col xs>
              <FeaturesCard
                title="Physiotherapy"
                description="Morning 06.00 to 08.00 - Evening 18.00 to 20.00"
                imgSrc="/assets/images/physiotherapy.jpg"
              />
            </Col>
            <Col xs>
              <FeaturesCard
                title="Stadium"
                description="Morning 06.00 to 08.00 - Evening 15.00 to 20.00"
                imgSrc="/assets/images/stadium.jpg"
              />
            </Col>
            <Col xs>
              <FeaturesCard
                title="Indoor Courts"
                description="Morning 05.00 to 08.00 - Evening 15.00 to 20.00"
                imgSrc="/assets/images/basketball.jpg"
              />
            </Col>
            <Col xs>
              <FeaturesCard
                title="Karate"
                description="Morning 05.00 to 08.00 - Evening 18.00 to 20.00"
                imgSrc="/assets/images/karate.jpg"
              />
            </Col>
            <Col xs>
              <FeaturesCard
                title="Gymnasiums"
                description="Morning 06.00 to 08.00 - Evening 15.00 to 20.00"
                imgSrc="/assets/images/gym.jpg"
              />
            </Col>
            <Col xs>
              <FeaturesCard
                title="Squash Court"
                description="Morning 05.00 to 08.00 - Evening 15.00 to 20.00"
                imgSrc="/assets/images/squash.jpg"
              />
            </Col>
          </Slider>
          {!isSafari && (
            <div className="gradient-blur">
              <div className="gradient-yellow"></div>
              <div className="gradient-blue"></div>
              <div className="gradient-orange"></div>
            </div>
          )}
        </Row>
      </Col>
    </Row>
  );
}

export default FeaturesSection;
