import { useState } from "react";
import { Row, Col } from "react-bootstrap";
import { isSafari } from "react-device-detect";

import PhysiotherapyIcon from "../../components/icons/PhysiotherapyIcon";
import AboutCard from "../../components/card/AboutCard";
import YogaIcon from "../../components/icons/YogaIcon";
import SwimmingPoolIcon from "../../components/icons/SwimmingPoolIcon";

function AboutSection() {
  const [readMore, setReadMore] = useState(false);
  return (
    <Row className="about-section align-items-center text-start" id="aboutUs">
      <Col xs={12} md={6}>
        {!isSafari && <div className="gradient-white"></div>}
        <h2 className="title">About Us</h2>
        <h6 className="sub-title">
          Who we are<div className="yellow-dot"></div>
        </h6>
        <p className="description">
          Integrated Police Sports Complex or Chandrasekharan Nair Stadium as it
          is popularly known is one of the premier sporting hubs in the state.
          Chandrasekharan Nair Stadium used to be a prominent football stadium
          in Thiruvananthapuram, Kerala, India. The stadium, also known as
          'Police Stadium', was constructed in honor of the first Inspector
          General of Police of Kerala Shri.N. Chandrasekharan Nair in 1956.
        </p>
        {readMore ? (
          <p className="description">
            It hosts both national and international level matches. It is run by
            a society (KPSYWS) Kerala Police Sports and Youth Welfare Society
            and over the years, the society has grown in proportion and in
            various facilities and has become one of the most sought after
            sporting centres of the state. The complex presently houses a
            physiotherapy centre, Karate centre, Albatross swimming pool,
            Gymnasiums, Yoga centre, Synthetic athletic track, Football ground,
            Indoor basketball court, Indoor volleyball court, Badminton complex,
            Squash court, AC and Non AC halls, Suite rooms and an Athletic
            dormitory. The self sufficient Sporting Complex, has contributed
            immensely to the development of sports and fitness, among the
            citizen, over the past many decades.
          </p>
        ) : null}
        <p className="read-more-button" onClick={() => setReadMore(!readMore)}>
          {readMore ? "Read less" : "Read more..."}
        </p>
      </Col>
      <Col xs={12} md={6} className="about-section-right">
        <Row className="card-container">
          <Col>
            <AboutCard
              title="Physiotherapy Centre"
              icon={<PhysiotherapyIcon />}
            />
          </Col>
          <Col>
            <AboutCard
              title="Yoga Centre"
              icon={<YogaIcon />}
            />
          </Col>
          <Col>
            <AboutCard
              title="Swimming Pool"
              icon={<SwimmingPoolIcon />}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default AboutSection;
