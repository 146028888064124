import { useFormik } from "formik";

import { gender } from "../../constants/constants";
import { addressForm } from "./schema";

import SearchButton from "../../components/buttons/SearchButton";
import BackButton from "../../components/buttons/BackButton";
import Input from "../../components/Inputs/Input";
import SelectInput from "../../components/Inputs/SelectInput";

function AddressForm(props) {
    const formik = useFormik({
        initialValues: {
            firstName: "",
            lastName: "",
            phone: "",
            gender: "",
            penNo: "",
            isPolice: props.isPolice
        },
        validationSchema: addressForm,
        onSubmit: () => { },
    });

    return (
        <div className="row g-4 login-form">
            <div className="col-md-6">
                <Input
                    name="firstName"
                    label="First name"
                    value={formik.values.firstName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.firstName && formik.errors.firstName}
                />
            </div>
            <div className="col-md-6">
                <Input
                    name="lastName"
                    label="Last name"
                    value={formik.values.lastName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.lastName && formik.errors.lastName}
                />
            </div>
            <div className="col-md-6">
                <Input
                    name="phone"
                    label="Mobile number"
                    value={formik.values.phone}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.phone && formik.errors.phone}
                />
            </div>
            <div className="col-md-6">
                <SelectInput
                    name="gender"
                    label="Gender"
                    options={gender}
                    value={formik.values.gender}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.gender && formik.errors.gender}
                />
            </div>
            {props.isPolice && (
                <div className="col-md-6">
                    <Input
                        name="penNo"
                        label="PEN Number"
                        value={formik.values.penNo}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                </div>
            )}
            <div className="col-md-12 pt-2">
                <SearchButton
                    text="Confirm Booking"
                    onClick={() => props.onNext(formik.values)}
                    disabled={!formik.dirty || !formik.isValid}
                />
            </div>
            <div className="col-md-12 pt-2">
                <BackButton
                    text="Back"
                    onClick={props.onBack}
                />
            </div>
        </div>
    );
}

export default AddressForm;
