import { Row, Col } from "react-bootstrap";
import PricingCard from "../../components/card/PricingCard";

function PricingSection() {
  const plans = [
    {
      planName: "Olymbia Auditorium",
      planFeatures: [
        "Capacity 500/300/200",
        "Cleaning Charges",
        "Charges for Electricity",
      ],
      planPrice: "Rent : Rs. 12000/8000/4500",
    },
    {
      planName: "Members Lounge",
      planFeatures: [
        "Furniture Rent Rs.2000",
        "Cleaning Charges",
        "Charges for Electricity",
      ],
      planPrice: "Rent : Rs.10000",
    },
    {
      planName: "Bhagyamala Auditorium",
      planFeatures: [
        "Capacity 500",
        "Cleaning Charges",
        "Charges for Electricity",
      ],
      planPrice: "Rent : Rs.8500",
    },
    {
      planName: "Arjuna Foyer Dining",
      planFeatures: [
        "Furniture Rent Rs.2000",
        "Cleaning Charges",
        "Charges for Electricity",
      ],
      planPrice: "Rent : Rs.7000",
    },
  ];

  return (
    <Row className="pricing-section" id="pricing">
      <Col xs={12}>
        <h2 className="title text-center">
          Pricing<div className="yellow-dot"></div>
        </h2>
        <p className="description text-center">
          Offering various types of Auditoriums which will be a perfect place
          for conducting events and functions of less than 500 guests at the
          heart of the City
        </p>
        <Row className="price-card-container gy-3">
          {plans.map((plan) => {
            return (
              <Col xs={12} md={6} lg={3} key={plan.planName}>
                <PricingCard
                  planName={plan.planName}
                  planFeatures={plan.planFeatures}
                  planPrice={plan.planPrice}
                />
              </Col>
            );
          })}
        </Row>
      </Col>
    </Row>
  );
}

export default PricingSection;
