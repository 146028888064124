import { Card } from "react-bootstrap";

import "./cards.scss";

function PricingCard({ planName, planFeatures, planPrice }) {
  return (
    <Card className="pricing-card">
      <Card.Body className="p-4 text-start">
        <p className="plan-name">{planName}</p>
        <ul className="plan-features">
          {planFeatures.map((feature) => {
            return <li key={feature}>{feature}</li>;
          })}
        </ul>
        <p className="plan-price p-2 px-4">{planPrice}</p>
      </Card.Body>
    </Card>
  );
}

export default PricingCard;
