import { useState } from "react";
import { Navbar, Container, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useAuthService } from "../../services/hooks";
import PromptModal from "../Modal/Modal";

import "./layout.scss";

function AuthHeader() {
  const [showPrompt, setShowPrompt] = useState(false)
  const { authLogout } = useAuthService()

  const handleLogout = () => {
    authLogout()
  }

  return (
    <Navbar collapseOnSelect expand="lg" variant="dark" className="nav-bar">
      <Container>
        <Navbar.Brand href="/" className="logo">
          <img src="/assets/images/logo.png" alt="logo" width="75px" />
          CSN Stadium
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto"></Nav>
          <Nav>
            <Link to="/" className="nav-link">
              Home
            </Link>
            <Link to="/my-bookings" className="nav-link">
              My Bookings
            </Link>
            <Link to="/dormitory-booking" className="nav-link">
              Dormitory Booking
            </Link>
            <Link to="#" onClick={() => setShowPrompt(true)} className="nav-link">
              Logout
            </Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
      <PromptModal
        show={showPrompt}
        heading="Logout confirmation"
        body="Are you sure you want to continue with logging out"
        handleClose={() => setShowPrompt(false)}
        handleConfirm={handleLogout}
      />
    </Navbar>
  );
}

export default AuthHeader;
