export const LOGIN_FETCHING = 'LOGIN_FETCHING'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_ERROR = 'LOGIN_ERROR'

export const SIGNUP_FETCHING = 'SIGNUP_FETCHING'
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS'
export const SIGNUP_ERROR = 'SIGNUP_ERROR'

export const SET_LOGGED_IN = 'SET_LOGGED_IN'
export const AUTH_LOGOUT = 'AUTH_LOGOUT'
export const RESET_AUTH_STATUS = 'RESET_AUTH_STATUS'

export const CHECK_BOOK_AVAILABILITY_FETCHING = 'CHECK_BOOK_AVAILABILITY_FETCHING'
export const CHECK_BOOK_AVAILABILITY_SUCCESS = 'CHECK_BOOK_AVAILABILITY_SUCCESS'
export const CHECK_BOOK_AVAILABILITY_ERROR = 'CHECK_BOOK_AVAILABILITY_ERROR'

export const CONFIRM_BOOKING_FETCHING = 'CONFIRM_BOOKING_FETCHING'
export const CONFIRM_BOOKING_SUCCESS = 'CONFIRM_BOOKING_SUCCESS'
export const CONFIRM_BOOKING_ERROR = 'CONFIRM_BOOKING_ERROR'

export const LIST_BOOKINGS_FETCHING = 'LIST_BOOKINGS_FETCHING'
export const LIST_BOOKINGS_SUCCESS = 'LIST_BOOKINGS_SUCCESS'
export const LIST_BOOKINGS_ERROR = 'LIST_BOOKINGS_ERROR'

export const GET_BOOKING_DETAILS_FETCHING = 'GET_BOOKING_DETAILS_FETCHING'
export const GET_BOOKING_DETAILS_SUCCESS = 'GET_BOOKING_DETAILS_SUCCESS'
export const GET_BOOKING_DETAILS_ERROR = 'GET_BOOKING_DETAILS_ERROR'

export const CANCEL_BOOKING_FETCHING = 'CANCEL_BOOKING_FETCHING'
export const CANCEL_BOOKING_SUCCESS = 'CANCEL_BOOKING_SUCCESS'
export const CANCEL_BOOKING_ERROR = 'CANCEL_BOOKING_ERROR'

export const RESET_BOOKING_STATUS = 'RESET_BOOKING_STATUS'

export const REFRESH_TOKEN = 'REFRESH_TOKEN'
