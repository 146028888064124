import React from "react";

function SwimmingPoolIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="512"
      height="512"
      fill="#FFA800"
      viewBox="0 0 512 512"
    >
      <path d="M19.105 365.835c194.577-55.312 265.697 56.463 481.68.085 8.016-2.092 12.817-10.287 10.725-18.302s-10.287-12.816-18.302-10.725c-42.403 11.069-79.157 15.166-112.44 15.21l-43.487-70.09c25.706 31.941 73.481 33.782 101.473 4.016 12.307-13.086 18.781-30.182 18.231-48.138-1.846-60.194-75.597-87.203-116.214-44.014-19.566 20.806-23.115 50.389-12.099 74.259l-59.504-95.904 88.302-25.481c27.423-7.913 40.383-39.303 26.45-64.27-10.117-18.128-31.115-26.77-51.059-21.014l-133.683 38.577c-34.661 10.003-50.183 50.295-31.151 80.971l40.406 65.123c-29.127 18.08-121.51 75.368-122.491 76.048-23.189 2.451-48.023 7.112-75.038 14.792-7.968 2.265-12.592 10.561-10.327 18.53 2.264 7.97 10.563 12.592 18.528 10.327zm343.519-151.404c14.12-15.014 37.69-15.695 52.661-1.616 14.998 14.105 15.723 37.661 1.616 52.662-14.103 14.996-37.661 15.723-52.661 1.616-14.999-14.106-15.723-37.662-1.616-52.662zm-120.793 28.638L193.518 165.2c-8.533-13.753-1.586-31.84 13.976-36.331l133.683-38.577c6.467-1.868 13.267.935 16.546 6.809 4.524 8.107.296 18.267-8.571 20.826L241.821 148.9c-9.553 2.757-13.82 13.887-8.587 22.32L344.52 350.582c-69.987-5.875-126.854-26.983-198.86-30.189l91.334-56.671c7.039-4.367 9.205-13.614 4.837-20.653zM493.208 406.893c-206.643 53.942-279.154-57.666-482.306.085-7.968 2.265-12.592 10.561-10.327 18.53s10.564 12.593 18.53 10.327c194.577-55.312 265.697 56.463 481.68.085 8.016-2.092 12.817-10.287 10.725-18.302-2.092-8.016-10.287-12.816-18.302-10.725z"></path>
    </svg>
  );
}

export default SwimmingPoolIcon;
