import { Container } from "react-bootstrap";

import Header from "../../components/layout/Header";
import './TermsAndCondition.scss'

function TermsAndCondition() {
    return (
        <div>
            <Container>
                <Header />
                <div className="text-white mt-5 pt-5">
                    <h2 className="text-center fw-bolder">Terms & Conditions for Dormitory Accommodation</h2>
                    <ol className="text-start mt-5">
                        <li>Do not use the dormitory for purposes other than intended without authorization.</li>
                        <li>Do not light fire in the dormitory for heating or cooking.</li>
                        <li>To prevent fire, refrain from smoking on bed, in non-smoking rooms, and in any other places prone to catch fire.</li>
                        <li>The equipment and articles in dormitory are strictly meant for the guests staying in the dormitory. Hence, inside the dormitory, use of such equipment and articles by outsiders is prohibited.</li>
                        <li>Do not bring the following inside the dormitory:<br />
                            a) Animals, birds, etc.<br />
                            b) Things giving off foul smell<br />
                            c) Articles exceeding the normal amount that can be carried into a dormitory.<br />
                            d) Guns, swords, etc.<br />
                            e) Explosives, or articles containing volatile oils that may ignite or catch fire.<br />
                            f) Any other articles that may pose a threat to the safety of other guests staying in the dormitory.</li>
                        <li>Do not scream, sing loudly, or create loud noises by any other actions inside the dormitory, as it may disturb or annoy other guests staying in the dormitory.</li>
                        <li>Refrain from engaging into gambling or acts that violate public order and morals inside the dormitory.</li>
                        <li>Do not distribute advertisement goods or sell articles to the other guests or collect donation or signatures from them inside the dormitory, without proper permission. </li>
                        <li>Note that we may refuse stay to patients suffering from an illness that may cause discomfort of any kind to the other guests inside the dormitory.</li>
                        <li>Do not leave your personal belongings in the passages or the lobby.</li>
                        <li>Any acts of photography that may bother the other guests in the dormitory are strictly prohibited inside the dormitory.</li>
                        <li>The Guest shall compensate the dormitory for any damage caused due to intent or negligence on part of the Guest.</li>
                        <li>Keep the dormitory and surroundings clean.</li>
                        <li>Ensure that the AC, lights and other switches are turned off when not in use.</li>
                        <li>The instructions of the personnel at reception must be abided by and if there are any inconveniences, kindly inform the duty officer.</li>
                        <li>Consumption of Alcohol and smoking is strictly prohibited in the dormitory.</li>
                        <li>Make sure to drop the used masks and food waste in the bin provided.</li>
                        <li>Ensure that food items cannot be consumed within the cabin. Use the assigned dining area while eating and any food waste is to be dropped in the bin. </li>
                    </ol>
                    <h2 className="text-center fw-bolder mt-3">Cancellation and Refund Rules</h2>
                    <p className="text-start mt-3">Bed booked for CSN Dormitory can be cancelled online and refund will be granted by the system as per CSN Refund Rules, as follows.</p>
                    <h5 className="text-start mt-3 fw-bold">Cancellation Charges for Confirmed beds</h5>
                    <p className="text-start mt-3">The amount deducted is based on the time of cancellation Detailed explanations are given below.</p>
                    <div className="cancellation-table mt-3">
                        <table className="table text-white">
                            <thead>
                                <tr>
                                    <th scope="col">Cancellation Time</th>
                                    <th scope="col">Cancellation Fee</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>7 days or more prior to check-in time</td>
                                    <td>Free cancellation (100% refund)</td>
                                </tr>
                                <tr>
                                    <td>4 - 6 days</td>
                                    <td>10% of booking value</td>
                                </tr>
                                <tr>
                                    <td>48 hours - 4 days</td>
                                    <td>20% of booking value</td>
                                </tr>
                                <tr>
                                    <td>24 hours - 48 hours</td>
                                    <td>40% of booking value</td>
                                </tr>
                                <tr>
                                    <td>Less than 24 hours before check-in time	</td>
                                    <td>`100% of booking value</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <h5 className="text-start mt-3 fw-bold">Refund Rules</h5>
                    <p className="text-start mt-3">	According to CSN Stadium refund rules, you can get a refund for Dormitory bed booked online through the CSN website by cancelling them online. Applicable cancellation fees will be deducted from the amount and the refund will be made within 15 days to the account through which you had made the payment.</p>
                    <h5 className="text-start mt-3 fw-bold">Refund for Failed Transactions</h5>
                    <p className="text-start mt-3">During dormitory booking through CSN website, if the amount is debited from your account but no bed has been issued to you, then the entire amount (except for the transaction charges for bank/cards) will be refunded to you by CSN.</p>
                </div>
            </Container>
        </div>
    );
}

export default TermsAndCondition;
