import { Card } from "react-bootstrap";

import "./cards.scss";

function AboutCard({ title, icon }) {
  return (
    <Card className="about-card p-4">
      <Card.Body className="card-body">
        <div>
          <div className="bg-round"></div>
          {icon}
          <p className="card-title">{title}</p>
        </div>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
