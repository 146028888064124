import { forwardRef } from 'react';
import { Helmet } from 'react-helmet-async';

const Page = forwardRef(({ children, title = '', ...other }, ref) => (
  <main ref={ref} {...other}>
    <Helmet>
      <title>{title}</title>
    </Helmet>
    {children}
  </main>
));

export default Page;
