import { Row, Col } from "react-bootstrap";
import { isSafari } from "react-device-detect";

import TestimonialCard from "../../components/card/TestimonialCard";

function TestimonialSection() {
  return (
    <Row className="testimonial-section gy-3" id="testimonial">
      <Col xs={12} md={6}>
        <TestimonialCard
          name="DR SHAIK DARVESH SAHEB IPS"
          position="DGP and State Police Chief, Kerala"
          imgSrc="/assets/images/shaik.svg"
        />
      </Col>
      <Col xs={12} md={6}>
        <TestimonialCard
          name="MANOJ ABRAHAM IPS"
          position="ADGP"
          imgSrc="/assets/images/manoj.svg"
        />
      </Col>
      {!isSafari && (
        <div className="gradient-blur">
          <div className="gradient-yellow"></div>
          <div className="gradient-blue"></div>
          <div className="gradient-orange"></div>
        </div>
      )}
    </Row>
  );
}

export default TestimonialSection;
