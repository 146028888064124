import { useContext } from 'react'
import { useFormik } from 'formik';
import { Link } from 'react-router-dom';

import { useAuth } from '../../services/context/AuthContext';
import { bedsList } from "../../constants/constants"
import { GlobalContext } from '../../services/context/ContextProvider';
import { FETCHING } from '../../services/constants';

import { searchForm } from './schema';
import RadioButton from '../../components/Inputs/RadioButton'
import SearchButton from "../../components/buttons/SearchButton"
import DatePickerInput from "../../components/Inputs/DatePicker"
import SelectInput from "../../components/Inputs/SelectInput"

function SearchForm(props) {
    const { globalState } = useContext(GlobalContext)
    const { authToken } = useAuth()
    const { booking } = globalState
    const isLoading = booking.checkAvailability.status === FETCHING

    const formik = useFormik({
        initialValues: {
            for: 'public',
            checkIn: '',
            checkOut: '',
            beds: ''
        },
        validationSchema: searchForm,
        onSubmit: () => { },
    });

    const filterPassedTime = (time) => {
        const currentDate = new Date();
        const selectedDate = new Date(time);
        return currentDate.getTime() < selectedDate.getTime();
    };

    const filterFeatureTime = (time) => {
        const currentDate = new Date(formik.values.checkIn)
        const selectedDate = new Date(time)
        return currentDate.getTime() < selectedDate.getTime();
    };

    return (
        <div className="row g-4">
            <div className="col-md-12">
                <label className="label">Booking for</label>
                <div className="form-group mt-3 d-flex">
                    <RadioButton
                        label="Public"
                        name="for"
                        value="public"
                        onClick={formik.handleChange}
                        defaultChecked
                    />
                    <RadioButton
                        label="Police officer"
                        name="for"
                        value="police"
                        onClick={formik.handleChange}
                    />
                </div>
            </div>
            <div className="col-md-6">
                <DatePickerInput
                    name="checkIn"
                    label="Check-in Date"
                    selected={formik.values.checkIn}
                    onChange={(e) => formik.setFieldValue("checkIn", Date.parse(e))}
                    showTimeSelect
                    minDate={new Date()}
                    filterTime={filterPassedTime}
                    error={formik.touched.checkIn && formik.errors.checkIn}
                    onBlur={formik.handleBlur}
                    onCalendarClose={() => formik.setFieldValue("checkOut", '')}
                />
            </div>
            <div className="col-md-6">
                <DatePickerInput
                    name="checkOut"
                    label="Check-out Date"
                    selected={formik.values.checkOut}
                    onChange={(e) => formik.setFieldValue("checkOut", Date.parse(e))}
                    showTimeSelect
                    minDate={(formik.values.checkIn ? formik.values.checkIn + 60 * 60 * 24 * 1000 : '')}
                    filterTime={filterFeatureTime}
                    disabled={!formik.values.checkIn}
                    error={formik.touched.checkOut && formik.errors.checkOut}
                    onBlur={formik.handleBlur}
                />
            </div>
            <div className="col-md-6">
                <SelectInput
                    label="No of beds"
                    name="beds"
                    options={bedsList}
                    onChange={formik.handleChange}
                    error={formik.touched.beds && formik.errors.beds}
                    onBlur={formik.handleBlur}
                />
            </div>
            <div className="col-md-12 text-end">
                <SearchButton text="Search"
                    isLoading={isLoading}
                    onClick={() => props.onNext(formik.values)}
                    disabled={!formik.dirty || !formik.isValid}
                />
            </div>
            <div className="col-md-12 text-end">
                {!authToken && <Link to='/login' className='login-button'>Login to your account!</Link>}
            </div>
        </div>
    );
}

export default SearchForm