import { useContext, useEffect } from "react";
import { useFormik } from "formik";
import { Container } from 'react-bootstrap'
import { toast } from "react-toastify";

import { ERROR, SUCCESS } from "../../services/constants";
import { useAuthService } from "../../services/hooks";
import { GlobalContext } from "../../services/context/ContextProvider";
import { loginForm } from "./schema";

import Input from "../../components/Inputs/Input";
import SearchButton from "../../components/buttons/SearchButton";
import Header from "../../components/layout/Header";
import Paper from "../../components/Paper/Paper";
import './Login.scss'

function Login(props) {
    const { globalState } = useContext(GlobalContext)
    const { auth } = globalState
    const { login, resetAuth } = useAuthService()

    const formik = useFormik({
        initialValues: {
            email: "",
            password: "",
        },
        validationSchema: loginForm,
        onSubmit: () => { },
    });

    useEffect(() => {
        if (auth.login.status === SUCCESS) {
            toast.success('Login successful')
            resetAuth()
        } else if (auth.login.status === ERROR) {
            toast.error(auth.login.data.error)
            resetAuth()
        }
    }, [auth.login, resetAuth])

    const handleNext = () => {
        const request = {
            email: formik.values.email,
            password: formik.values.password
        }
        login(request)
    }

    return (
        <div className="login">
            <Container>
                <Header />
                <div className="form">
                    <Paper>
                        <h1 className="title">Login</h1>
                        <div className="row g-4 login-form">
                            <div className="col-md-12">
                                <Input
                                    name="email"
                                    label="Email address"
                                    value={formik.values.email}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.email && formik.errors.email}
                                />
                            </div>
                            <div className="col-md-12">
                                <Input
                                    name="password"
                                    label="Password"
                                    type="password"
                                    value={formik.values.password}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.password && formik.errors.password}
                                />
                            </div>
                            <div className="col-md-12 pt-2">
                                <SearchButton
                                    text="Login"
                                    disabled={!formik.dirty || !formik.isValid}
                                    onClick={handleNext}
                                />
                            </div>
                        </div>
                    </Paper>
                </div>
            </Container>
        </div>
    );
}

export default Login
