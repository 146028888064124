import { Navbar, Container, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import RegisterButton from "../buttons/RegisterButton";

import "./layout.scss";

function Header() {
  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      variant="dark"
      className="nav-bar"
    >
      <Container>
        <Navbar.Brand href="/" className="logo">
          <img src="/assets/images/logo.png" alt="logo" width="75px" />
          CSN Stadium
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto"></Nav>
          <Nav>
            <Nav.Link href="#" className="nav-link">
              Home
            </Nav.Link>
            <Nav.Link href="#aboutUs" className="nav-link">
              About Us
            </Nav.Link>
            <Nav.Link href="#features" className="nav-link">
              Features
            </Nav.Link>
            <Nav.Link href="#pricing" className="nav-link">
              Pricing
            </Nav.Link>
            <Nav.Link href="#contact" className="nav-link">
              Contact Us
            </Nav.Link>
            <Link to='/dormitory-booking'><RegisterButton text="Dormitory Booking" /></Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
