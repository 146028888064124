import axios from 'axios'

const getDefaultHeaders = () => ({
  'Content-Type': 'application/json',
  Accept: 'application/json',
  Authorization: `Bearer ${JSON.parse(localStorage.getItem('tokens')).accessToken}`
})

const axiosInstance = ({
  url, params, header = {}
}) => axios.create({
  baseURL: url,
  params,
  timeout: 120000,
  headers: {
    ...getDefaultHeaders(),
    ...header
  }
})

export default axiosInstance
