import { Col, Row } from "react-bootstrap";
import RegisterButton from "../../components/buttons/RegisterButton";

function Footer() {
  return (
    <Row
      className="footer justify-content-between align-items-end"
      id="contact"
    >
      <Col xs={12} md={6} className="text-start">
        <h2 className="heading">Contact info</h2>
        <p className="address">
          Chandrasekharan Nair Stadium Near CSI Church, Mahathma Gandhi Rd,
          University of Kerala Senate House Campus, Palayam, Thiruvananthapuram,
          Kerala 695033
        </p>
        <p className="m-0">
          <a href="mailto:Secretarykpsyws@gmail.com" className="address">
            Secretarykpsyws@gmail.com
          </a>
        </p>
      </Col>
      <Col xs={12} md={6} className="text-start text-md-end gy-5">
        <p className="contact">For more Queries Call Us at</p>
        <RegisterButton text="0471 2305251" />
      </Col>
      <Col
        xs={12}
        className="copyright d-flex justify-content-center align-items-center"
      >
        All Rights Reserved 2023
      </Col>
    </Row>
  );
}

export default Footer;
