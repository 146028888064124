import * as yup from 'yup';

const phoneRegExp = /^(\+91[\-\s]?)?[0]?(91)?[789]\d{9}$/;

export const searchForm = yup.object({
    checkIn: yup
        .string('Check-in date is required')
        .required('Check-in date is required'),
    checkOut: yup
        .string('Check-out date is required')
        .required('Check-out date is required'),
    beds: yup
        .string('Select no of beds is required')
        .required('Select no of beds is required'),
});

export const loginForm = yup.object({
    email: yup
        .string('Enter your email')
        .email('Enter a valid email')
        .required('Email is required'),
    password: yup
        .string('Enter your password')
        .min(8, 'Password should be of minimum 8 characters length')
        .required('Password is required'),
})

export const addressForm = yup.object({
    firstName: yup
        .string('Enter your first name')
        .required('First name is required'),
    lastName: yup
        .string('Enter your last name')
        .required('Last name is required'),
    phone: yup
        .string().matches(phoneRegExp, 'Phone number is not valid')
        .required('Phone number is required'),
    gender: yup
        .string('Select your gender')
        .required('Gender is required'),
})