import { Card } from "react-bootstrap";
import RegisterButton from "../buttons/RegisterButton";

import "./cards.scss";

function FeaturesCard({ title, description, imgSrc }) {
  return (
    <Card className="features-card">
      <Card.Body>
        <h6 className="card-title">{title}</h6>
        <p className="description">{description}</p>
        <img className="card-image" src={imgSrc} alt={title} />
        <div className="enroll-button">
          <RegisterButton text="Enroll Now" />
        </div>
      </Card.Body>
    </Card>
  );
}

export default FeaturesCard;
