import { useFormik } from "formik";

import { loginForm } from "./schema";

import SearchButton from "../../components/buttons/SearchButton";
import Input from "../../components/Inputs/Input";

function LoginForm(props) {
    const formik = useFormik({
        initialValues: {
            email: "",
            password: "",
        },
        validationSchema: loginForm,
        onSubmit: () => { },
    });

    return (
        <div className="row g-4 login-form">
            <div className="col-md-12">
                <Input
                    name="email"
                    label="Email address"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.email && formik.errors.email}
                />
            </div>
            <div className="col-md-12">
                <Input
                    name="password"
                    label="Password"
                    type="password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.password && formik.errors.password}
                />
            </div>
            <div className="col-md-12 pt-2">
                <SearchButton
                    text="Next"
                    disabled={!formik.dirty || !formik.isValid}
                    onClick={() => props.onNext(formik.values)}
                />
            </div>
            <p className="login-button" onClick={props.onClickSignup}>
                Create new account!
            </p>
        </div>
    );
}

export default LoginForm;
