const combineReducers = reducers => {
  return (state, action) => {
    const tempState = { ...state }
    Object.keys(tempState).forEach((key) => {
      tempState[key] = reducers[key](tempState[key], action)
    })
    return tempState
  }
}

export default combineReducers
