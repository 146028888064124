import combineReducers from './../utilityService'
import authReducer, { initialAuthState } from './auth'
import bookingReducer, { initialBookingState } from './booking'

const reducer = combineReducers({
    auth: authReducer,
    booking: bookingReducer
})

export const initialState = {
    auth: initialAuthState,
    booking: initialBookingState
}
export default reducer
