import { Modal } from 'react-bootstrap'
import BackButton from '../buttons/BackButton'
import SearchButton from '../buttons/SearchButton'
import './Modal.scss'

function PromptModal({ heading, body, body1, show, handleClose, handleConfirm }) {
    return (
        <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} className='modal' size="md">
            <Modal.Header closeButton>
                <Modal.Title>{heading}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>{body}</div>
            </Modal.Body>
            <Modal.Footer>
                <div className='row w-100 gy-3 mb-4'>
                    <div className='col-md-6'>
                        <BackButton text="Cancel" onClick={handleClose} />
                    </div>
                    <div className='col-md-6'>
                        <SearchButton text="Confirm" onClick={handleConfirm} />
                    </div>
                </div>
            </Modal.Footer>
        </Modal>
    )
}

export default PromptModal