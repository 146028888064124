import { Container } from "react-bootstrap"

import { GlobalContext } from "../../services/context/ContextProvider"
import { useContext, useEffect } from "react"
import { useBookingService } from "../../services/hooks"

import AuthHeader from "../../components/layout/AuthHeader"
import Paper from "../../components/Paper/Paper"
import BookingHistoryCard from "../../components/card/BookingHistoryCard"
import Page from "../../components/layout/Page"
import './MyBookings.scss'

function MyBookings() {
    const { globalState } = useContext(GlobalContext)
    const { booking } = globalState

    const { listBookings } = useBookingService()
    console.log(booking)

    useEffect(() => {
        listBookings()
    }, [listBookings])

    const getBookingDate = (startDate, endDate) => {
        const option = {
            year: "numeric",
            month: "short",
            day: "numeric"
        }
        return new Date(startDate).toLocaleDateString([], option) + '-' + new Date(endDate).toLocaleDateString([], option)
    }

    return (
        <div className="my-bookings">
            <Container>
                <Page title="My Bookings | CSN Stadium">
                    <AuthHeader />
                    <div className="bookings-card">
                        <Paper>
                            <div className="row justify-content-between align-items-center">
                                <div className="col-auto">
                                    <h3 className="title">Booking History</h3>
                                </div>
                                <div className="col-auto">Upcoming</div>
                            </div>
                            <div className="row mt-4">
                                {booking?.bookings?.data?.map((booking) => {
                                    return (
                                        <div className="col-sm-12" key={booking.id}>
                                            <BookingHistoryCard
                                                id={booking.id}
                                                type="Deluxe 6 Bed Mixed Dorm"
                                                date={getBookingDate(booking.start_ts, booking.end_ts)}
                                                beds={`${booking.reservations} ${booking.reservations === 1 ? 'Guest' : 'Guests'}`}
                                                status={`${booking.status === 'confirmed'
                                                    ? 'Booking Confirmed' : booking.status === 'cancelled'
                                                        ? 'Cancelled' : booking.status === 'payment-pending'
                                                            ? 'Payment Pending' : ''
                                                    }`}
                                                bookingId={`Booking ID: ${booking.code}`}
                                            />
                                        </div>
                                    )
                                })}
                                {!booking?.bookings?.data?.length && <p className="mt-4">No bookings found</p>}
                            </div>
                        </Paper>
                    </div>
                </Page>
            </Container>
        </div>
    )
}

export default MyBookings
