import { useCallback, useContext } from "react"
import axios from "axios"

import { loginError, loginFetch, loginSuccess, setLoggedIn, signupError, signupFetch, signupSuccess, resetAuthStatus } from "../actions"
import { endPoints } from "../constants"
import { GlobalContext } from "../context/ContextProvider"


export const useAuthService = () => {
    const { dispatch } = useContext(GlobalContext)

    const login = useCallback(async (request) => {
        dispatch(loginFetch())
        try {
            const { data } = await axios.post(endPoints.LOGIN, request)
            setSessionData(data)
            dispatch(setLoggedIn(true))
            dispatch(loginSuccess(data))
        } catch (error) {
            dispatch(loginError(error.response.data))
        }
    }, [dispatch])

    const signup = useCallback(async (request) => {
        dispatch(signupFetch())
        try {
            const { data } = await axios.post(endPoints.SIGNUP, request)
            setSessionData(data)
            dispatch(setLoggedIn(true))
            dispatch(signupSuccess(data))
        } catch (error) {
            dispatch(signupError(error.response.data))
        }
    }, [dispatch])

    const authLogout = () => {
        localStorage.removeItem('tokens')
        dispatch(setLoggedIn(false))
    }

    const refreshToken = () => {
        const tokenData = JSON.parse(localStorage.getItem('tokens'))
        const now = new Date().getTime()
        if (!tokenData) {
            dispatch(authLogout())
        } else {
            if (tokenData.accessToken && tokenData.tokenExpiry > now) {
                dispatch(setSessionData(tokenData))
                dispatch(setLoggedIn(tokenData))
            } else {
                dispatch(authLogout())
            }
        }
    }

    const resetAuth = useCallback(async () => {
        dispatch(resetAuthStatus())
    }, [dispatch])

    return { login, signup, authLogout, refreshToken, resetAuth }
}

const setSessionData = response => {
    const sessionData = {
        userId: response.id,
        accessToken: response.access_token,
        accessTokenExpiry: parseInt(response.valid_till),
        email: response.email
    }
    localStorage.setItem('tokens', JSON.stringify(sessionData))
}