import { AUTH_LOGOUT, LOGIN_ERROR, LOGIN_FETCHING, LOGIN_SUCCESS, RESET_AUTH_STATUS, SET_LOGGED_IN, SIGNUP_ERROR, SIGNUP_FETCHING, SIGNUP_SUCCESS } from "../actionTypes"
import { IDLE, ERROR, FETCHING, SUCCESS } from "../constants"

export const initialAuthState = {
    login: {
        status: IDLE,
        data: {}
    },
    signup: {
        data: {},
        status: IDLE
    }
}

const authReducer = (state = initialAuthState, { type, data } = {}) => {
    switch (type) {
        case SET_LOGGED_IN:
            return { ...state, isLoggedIn: data }

        case AUTH_LOGOUT:
            return { ...state, isLoggedIn: null }

        case LOGIN_FETCHING:
            return { ...state, login: { status: FETCHING } }
        case LOGIN_SUCCESS:
            return { ...state, login: { status: SUCCESS, data } }
        case LOGIN_ERROR:
            return { ...state, login: { status: ERROR, data } }

        case SIGNUP_FETCHING:
            return { ...state, signup: { status: FETCHING } }
        case SIGNUP_SUCCESS:
            return { ...state, signup: { status: SUCCESS, data } }
        case SIGNUP_ERROR:
            return { ...state, signup: { status: ERROR, data } }

        case RESET_AUTH_STATUS:
            return { ...state, login: { status: IDLE, data: {} }, signup: { status: IDLE, data: {} } }

        default:
            return state
    }
}

export default authReducer