import { Col, Row } from "react-bootstrap";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { isSafari } from "react-device-detect";

import LocationIcon from "../../components/icons/LocationIcon";
import RegisterButton from "../../components/buttons/RegisterButton";
import CallButton from "../../components/buttons/CallButton";

function HomeSection() {
  const settings = {
    dots: true,
    lazyLoad: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 2,
    autoplay: true,
    autoplaySpeed: 2000,
  };
  return (
    <Row className="align-items-center home-section text-white gy-5">
      <Col xs={12} md={6} className="text-start">
        <h1 className="text-white title">Integrated Police Sports Complex</h1>
        <div className="address-card">
          <p className="p-2">
            <Row>
              <Col xs={1}>
                <LocationIcon />
              </Col>
              <Col className="mx-2">
                Chandrasekharan Nair Stadium
                <br />
                Thiruvananthapuram, Kerala, India.
              </Col>
            </Row>
          </p>
        </div>
        <p className="description">
          Integrated Police Sports Complex or Chandrasekharan Nair Stadium as it
          is popularly known is one of the premier sporting hubs in the state.
        </p>
        <div className="d-flex">
          <div>
            <Link to="/dormitory-booking"><RegisterButton text="Dormitory Booking" /></Link>
          </div>
          <div className="mx-3">
            <CallButton
              icon={<i className="fas fa-phone-alt"></i>}
              text="Call Us"
            />
          </div>
        </div>
      </Col>
      <Col xs={12} md={6} className="slider">
        <Slider {...settings}>
          <div>
            <img
              src="/assets/images/yoga-training.jpg"
              alt="1"
              className="slider-img"
              width="100%"
            />
          </div>
          <div>
            <img
              src="/assets/images/karate.jpg"
              alt="2"
              className="slider-img"
              width="100%"
            />
          </div>
          <div>
            <img
              src="/assets/images/gym.jpg"
              alt="2"
              className="slider-img"
              width="100%"
            />
          </div>
        </Slider>
        {!isSafari && (
          <div className='bg-gradient-blur'>
            <div className="color-gradient-yellow"></div>
            <div className="color-gradient-blue"></div>
            <div className="color-gradient-orange"></div>
          </div>
        )}
      </Col>
    </Row>
  );
}

export default HomeSection;
