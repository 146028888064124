import React, { useContext, useEffect, useRef, useState } from "react"
import { Container } from "react-bootstrap"
import { useNavigate, useParams } from "react-router-dom"
import { useReactToPrint } from "react-to-print"
import { toast } from "react-toastify"

import { ERROR, SUCCESS } from "../../services/constants"
import { GlobalContext } from "../../services/context/ContextProvider"
import { useBookingService } from "../../services/hooks"

import SearchButton from "../../components/buttons/SearchButton"
import AuthHeader from '../../components/layout/AuthHeader'
import PromptModal from "../../components/Modal/Modal"
import Paper from "../../components/Paper/Paper"
import Page from "../../components/layout/Page"
import './BookingDetails.scss'

function BookingDetails() {
    const [showPrompt, setShowPrompt] = useState(false)
    const componentRef = useRef();
    const params = useParams()
    const navigate = useNavigate()

    const { globalState } = useContext(GlobalContext)
    const { booking } = globalState
    const { getBookingDetails, cancelBooking, resetBooking } = useBookingService()

    const bookingData = booking.bookingDetails.data

    useEffect(() => {
        getBookingDetails(params.id)
    }, [params.id, getBookingDetails])

    useEffect(() => {
        if (booking.cancelBooking.status === SUCCESS) {
            toast.success("Booking cancelled successfully")
            navigate('/my-bookings')
            resetBooking()
        } else if (booking.cancelBooking.status === ERROR) {
            toast.error(booking.cancelBooking.data.message)
        }
    }, [booking.cancelBooking, navigate, resetBooking])

    const getDate = (timestamp) => {
        const date = new Date(timestamp)
        return date.toLocaleDateString()
    }
    const getTime = (timestamp) => {
        const date = new Date(timestamp)
        return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
    }
    const gotoLocation = () => {
        window.open('https://goo.gl/maps/gzwdQYkgsDDBuemv7', '_blank')
    }

    const handlePrint = useReactToPrint({
        content: () => componentRef.current
    });

    const handleCancel = () => {
        cancelBooking(params.id)
        setShowPrompt(false)
    }

    const ComponentToPrint = React.forwardRef((props, ref) => (
        <div ref={componentRef} className="bg-white text-dark p-5 opacity-100">
            <div className="text-start my-4">
                <img src="/assets/images/logo.png" alt="logo" width="75px" />
            </div>
            <div className="row justify-content-between">
                <div className="col-auto text-start">
                    <h3 className="title">Booking Id</h3>
                    <p className="booking-id">{bookingData?.code}</p>
                </div>
                <div className="col-md-4 text-start text-md-end">
                    <h3 className="title">Status</h3>
                    <div className="caption">
                        {bookingData?.status === 'confirmed' && "Confirmed"}
                        {bookingData?.status === 'cancelled' && "Cancelled"}
                        {bookingData?.status === 'payment-pending' && "Payment Pending"}
                    </div>
                </div>
            </div>
            <hr></hr>
            <div className="address text-start">
                <h5 className="title">Address</h5>
                <h6 className="sub-heading">Chandrasekharan Nair Stadium - Trivandrum</h6>
                <p className="caption">Mahathma Gandhi Rd, University of Kerala Senate House Campus,<br />
                    Palayam, Thiruvananthapuram, Kerala<br />
                    Pincode - 695033
                </p>
            </div>
            <hr></hr>
            <div className="guest-details">
                <div className="row text-start">
                    <div className="col-md-4">
                        <p className="head-title">Check In</p>
                        <p className="head-caption">{getDate(bookingData?.start_ts)}</p>
                    </div>
                    <div className="col-md-4">
                        <p className="head-title">Check In Time</p>
                        <p className="head-caption">{getTime(bookingData?.start_ts)}</p>
                    </div>
                </div>
                <div className="row text-start">
                    <div className="col-md-4">
                        <p className="head-title">Check Out</p>
                        <p className="head-caption">{getDate(bookingData?.end_ts)}</p>
                    </div>
                    <div className="col-md-4">
                        <p className="head-title">Check Out Time</p>
                        <p className="head-caption">{getTime(bookingData?.end_ts)}</p>
                    </div>
                    <div className="col-md-12">
                        <p className="head-title">Beds</p>
                        <p className="head-caption">
                            {bookingData?.reservations} {bookingData?.reservations === 1 ? 'Guest' : 'Guests'}
                        </p>
                    </div>
                </div>
            </div>
            <hr></hr>
            <div className="payment-details text-start">
                <p className="heading">Payment details</p>
                <p className="head-caption">Total Amount : Rs.{bookingData?.total_charge}</p>
                <p className="head-caption">
                    {bookingData?.payment_status === 'not-paid' && 'Not Paid'}
                    {bookingData?.payment_status === 'paid' && 'Paid'}
                </p>
            </div>
        </div>
    ));

    return (
        <div className="booking-details">
            <Container>
                <Page title="Booking Details | CSN Stadium">
                    <AuthHeader />
                    <div className="details-card">
                        <Paper>
                            <div className="row justify-content-between">
                                <div className="col-auto text-start">
                                    <h3 className="title">Booking Id</h3>
                                    <p className="booking-id">{bookingData?.code}</p>
                                </div>
                                <div className="col-md-4 text-start text-md-end">
                                    <h3 className="title">Status</h3>
                                    <div className="caption">
                                        {bookingData?.status === 'confirmed' && "Confirmed"}
                                        {bookingData?.status === 'cancelled' && <p className="text-danger">Cancelled</p>}
                                        {bookingData?.status === 'payment-pending' && "Payment Pending"}
                                    </div>
                                </div>
                                {bookingData?.payment_status === 'failed' && <p className="payment-failure-error">Your payment was not successful. Please try again</p>}
                                <SearchButton text="Return to home" onClick={() => navigate('/')} />
                            </div>
                            <hr></hr>
                            <div className="address text-start">
                                <h5 className="title">Address</h5>
                                <h6 className="sub-heading">Chandrasekharan Nair Stadium - Trivandrum</h6>
                                <p className="caption">Mahathma Gandhi Rd, University of Kerala Senate House Campus,<br />
                                    Palayam, Thiruvananthapuram, Kerala<br />
                                    Pincode - 695033
                                </p>
                            </div>
                            <hr></hr>
                            <div className="guest-details">
                                <div className="row text-start">
                                    <div className="col-md-4">
                                        <p className="head-title">Check In</p>
                                        <p className="head-caption">{getDate(bookingData?.start_ts)}</p>
                                    </div>
                                    <div className="col-md-4">
                                        <p className="head-title">Check In Time</p>
                                        <p className="head-caption">{getTime(bookingData?.start_ts)}</p>
                                    </div>
                                </div>
                                <div className="row text-start">
                                    <div className="col-md-4">
                                        <p className="head-title">Check Out</p>
                                        <p className="head-caption">{getDate(bookingData?.end_ts)}</p>
                                    </div>
                                    <div className="col-md-4">
                                        <p className="head-title">Check Out Time</p>
                                        <p className="head-caption">{getTime(bookingData?.end_ts)}</p>
                                    </div>
                                    <div className="col-md-12">
                                        <p className="head-title">Beds</p>
                                        <p className="head-caption">
                                            {bookingData?.reservations} {bookingData?.reservations === 1 ? 'Guest' : 'Guests'}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <hr></hr>
                            <div className="payment-details text-start">
                                <p className="heading">Payment details</p>
                                <p className="head-caption">Total Amount : Rs.{bookingData?.total_charge}</p>
                                <p className="head-caption">
                                    {bookingData?.payment_status === 'not-paid' && 'Not Paid'}
                                    {bookingData?.payment_status === 'paid' && 'Paid'}
                                </p>
                            </div>
                            <div className="actions row gy-4 mt-4">
                                <div className="col-md-6">
                                    {bookingData?.payment_status === 'failed' ? <SearchButton text="Return to home" onClick={() => navigate('/')} /> : <SearchButton text="Print" onClick={handlePrint} />}
                                </div>
                                <div className="col-md-6">
                                    <SearchButton text="Direction" onClick={gotoLocation} />
                                </div>
                                {bookingData?.status !== 'cancelled' && <p className="pt-2 cancel-button" onClick={() => setShowPrompt(true)}>Cancel booking</p>}
                            </div>
                        </Paper>
                        <div className="d-none">
                            <ComponentToPrint ref={componentRef} />
                        </div>
                    </div>
                </Page>
            </Container>
            <PromptModal
                show={showPrompt}
                heading="Confirm Cancel"
                body="Are you sure you want to cancel booking, you will deduct 25% of the booking amount"
                handleClose={() => setShowPrompt(false)}
                handleConfirm={handleCancel}
            />
        </div>
    )
}

export default BookingDetails
