import HomeSection from "./HomeSection";
import AboutSection from "./AboutSection";
import FeaturesSection from "./FeaturesSection";
import PricingSection from "./PricingSection";
import TestimonialSection from "./TestimonialSection";
import Footer from "./Footer";
import Header from "../../components/layout/Header";

import "./Home.scss";
import { Container } from "react-bootstrap";

function Home() {
  return (
    <div className="home text-white">
      <Container>
        <Header />
        <HomeSection />
        <AboutSection />
        <FeaturesSection />
        <PricingSection />
        <TestimonialSection />
        <Footer />
      </Container>
    </div>
  );
}

export default Home;
