
export const time = [
    { id: '12:00 AM' },
    { id: '01:00 AM' },
    { id: '02:00 AM' },
    { id: '03:00 AM' },
    { id: '04:00 AM' },
    { id: '05:00 AM' },
    { id: '06:00 AM' },
    { id: '07:00 AM' },
    { id: '08:00 AM' },
    { id: '09:00 AM' },
    { id: '10:00 AM' },
    { id: '11:00 AM' },
    { id: '12:00 PM' },
    { id: '01:00 PM' },
    { id: '02:00 PM' },
    { id: '03:00 PM' },
    { id: '04:00 PM' },
    { id: '05:00 PM' },
    { id: '06:00 PM' },
    { id: '07:00 PM' },
    { id: '08:00 PM' },
    { id: '09:00 PM' },
    { id: '10:00 PM' },
    { id: '11:00 PM' },
]

export const bedsList = [
    { id: 1, value: 'One', },
    { id: 2, value: 'Two' },
    { id: 3, value: 'Cabin of 3 Beds' },
    { id: 4, value: 'Cabin of 4 Beds' },
    { id: 5, value: 'Cabin of 5 Beds' },
    { id: 6, value: 'Cabin of 6 Beds' },
]

export const gender = [
    { id: 'men', value: "Men" },
    { id: 'women', value: 'Women' },
    { id: 'other', value: 'other' },
]