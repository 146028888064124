import { CANCEL_BOOKING_ERROR, CANCEL_BOOKING_FETCHING, CANCEL_BOOKING_SUCCESS, CHECK_BOOK_AVAILABILITY_ERROR, CHECK_BOOK_AVAILABILITY_FETCHING, CHECK_BOOK_AVAILABILITY_SUCCESS, CONFIRM_BOOKING_ERROR, CONFIRM_BOOKING_FETCHING, CONFIRM_BOOKING_SUCCESS, GET_BOOKING_DETAILS_ERROR, GET_BOOKING_DETAILS_FETCHING, GET_BOOKING_DETAILS_SUCCESS, LIST_BOOKINGS_ERROR, LIST_BOOKINGS_FETCHING, LIST_BOOKINGS_SUCCESS, RESET_BOOKING_STATUS } from "../actionTypes"

export const checkBookAvailabilityFetching = () => ({ type: CHECK_BOOK_AVAILABILITY_FETCHING })
export const checkBookAvailabilitySuccess = (data) => ({ type: CHECK_BOOK_AVAILABILITY_SUCCESS, data })
export const checkBookAvailabilityError = (data) => ({ type: CHECK_BOOK_AVAILABILITY_ERROR, data })

export const confirmBookingFetching = (data) => ({ type: CONFIRM_BOOKING_FETCHING })
export const confirmBookingSuccess = (data) => ({ type: CONFIRM_BOOKING_SUCCESS, data })
export const confirmBookingError = (data) => ({ type: CONFIRM_BOOKING_ERROR, data })

export const listBookingsFetching = () => ({ type: LIST_BOOKINGS_FETCHING })
export const listBookingsSuccess = (data) => ({ type: LIST_BOOKINGS_SUCCESS, data })
export const listBookingsError = (data) => ({ type: LIST_BOOKINGS_ERROR, data })

export const getBookingDetailsFetching = () => ({ type: GET_BOOKING_DETAILS_FETCHING })
export const getBookingDetailsSuccess = (data) => ({ type: GET_BOOKING_DETAILS_SUCCESS, data })
export const getBookingDetailsError = (data) => ({ type: GET_BOOKING_DETAILS_ERROR, data })

export const cancelBookingFetching = () => ({ type: CANCEL_BOOKING_FETCHING })
export const cancelBookingSuccess = (data) => ({ type: CANCEL_BOOKING_SUCCESS, data })
export const cancelBookingError = (data) => ({ type: CANCEL_BOOKING_ERROR, data })

export const resetBookingStatus = () => ({ type: RESET_BOOKING_STATUS })
