import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";

import "./cards.scss";

function BookingHistoryCard(props) {
    return (
        <Card className="booking-history-card" onClick={props.onClick}>
            <Card.Body className="card-body">
                <div className="row align-items-center justify-content-between">
                    <div className="col-md-8">
                        <div className="row gy-4 gx-5 align-items-center">
                            <div className="col-md-3">
                                <img className="preview-image" src="https://a.hwstatic.com/image/upload/f_auto,q_auto,t_30/v1/propertyimages/3/304638/ywx5z1ale4vtvahhtf49" width={100} height={100} alt="dormitory" />
                            </div>
                            <div className="col-md-8 text-start">
                                <p className="type">{props.type}</p>
                                <p className="booked-date">{props.date}</p>
                                <p className="guest-count">{props.beds}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-auto text-start text-md-end pt-2 pt-md-0">
                        <p className="booking-status">{props.status}</p>
                        <p className="booking-code">{props.bookingId}</p>
                        <p className="view-button"><Link to={`/booking/${props.id}`}>View details</Link></p>
                    </div>
                </div>
            </Card.Body>
        </Card>
    )
}

export default BookingHistoryCard