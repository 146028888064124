import DatePicker from "react-datepicker";
import './inputs.scss'

function DatePickerInput(props) {
    return (
        <div className='form-group date-picker-input'>
            <label className="label">{props.label}</label>
            <DatePicker
                name={props.name}
                selected={props.selected}
                onChange={props.onChange}
                onBlur={props.onBlur}
                className='date-picker mt-2'
                minDate={props.minDate}
                minTime={props.minTime}
                showTimeSelect={props.showTimeSelect}
                dateFormat="dd/MM/yyyy h:mm aa"
                filterTime={props.filterTime}
                filterDate={props.filterDate}
                startDate={props.startDate}
                disabled={props.disabled}
                onCalendarClose={props.onCalendarClose}
                placeholderText={props.label}
            />
            {props.error && <p className="error text-warning">{props.error}</p>}
        </div>
    )
}

export default DatePickerInput