import { SUCCESS } from './constants'

export const getAccessToken = () => {
  const tokenData = JSON.parse(localStorage.getItem('tokens'))
  if (!tokenData) {
    return {
      status: SUCCESS,
      accessToken: ''
    }
  } else if (tokenData.accessTokenExpiry > new Date().getTime()) {
    return {
      status: SUCCESS,
      userId: tokenData.userId,
      accessToken: tokenData.accessToken
    }
  } else {
    localStorage.setItem('tokens', JSON.stringify(''))
    return {
      status: SUCCESS,
      accessToken: ''
    }
  }
}