import { useState } from "react";
import BackButton from "../../components/buttons/BackButton";
import BookingPreviewCard from "../../components/card/BookingPreviewCard"
import PreviewModal from "../../components/Modal/ImagePreviewModal";

function SearchResults(props) {
    const [openPreview, setOpenPreview] = useState(false)

    const openImagePreview = (e) => {
        e.stopPropagation()
        e.preventDefault()
        setOpenPreview(true)
    }
    return (
        <div className="search-result row">
            {props.result.available_beds && props.result.status > 0 ? (
                <BookingPreviewCard
                    onClickPreview={openImagePreview}
                    onClick={props.onNext}
                    type="Deluxe 6 Bed Dorm(AC)"
                    dailyCharge={`Rs.${props.result.daily_charge} per day`}
                    total={`Total: Rs.${props.result.daily_charge * props.result.reservations} x ${props.result.days_count} Days = Rs.${props.result.total_charge}`}
                />
            ) : (
                <div className="row g-4 m-0">
                    <div className="col-md-12 m-0">
                        <p>{props.result.message ? props.result.message : 'No beds available for the search criteria'}</p>
                    </div>
                </div>
            )}
            <div className="col-sm-12">
                <BackButton text="Back" onClick={props.onCancel} />
            </div>
            <PreviewModal show={openPreview} onClose={() => setOpenPreview(false)} />
        </div>
    );
}

export default SearchResults