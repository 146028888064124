import { CANCEL_BOOKING_ERROR, CANCEL_BOOKING_FETCHING, CANCEL_BOOKING_SUCCESS, CHECK_BOOK_AVAILABILITY_ERROR, CHECK_BOOK_AVAILABILITY_FETCHING, CHECK_BOOK_AVAILABILITY_SUCCESS, CONFIRM_BOOKING_ERROR, CONFIRM_BOOKING_FETCHING, CONFIRM_BOOKING_SUCCESS, GET_BOOKING_DETAILS_ERROR, GET_BOOKING_DETAILS_FETCHING, GET_BOOKING_DETAILS_SUCCESS, LIST_BOOKINGS_ERROR, LIST_BOOKINGS_FETCHING, LIST_BOOKINGS_SUCCESS, RESET_BOOKING_STATUS, } from "../actionTypes"
import { ERROR, FETCHING, IDLE, SUCCESS } from "../constants"

export const initialBookingState = {
    checkAvailability: {
        status: IDLE,
        data: {}
    },
    confirmBooking: {
        status: IDLE,
        data: {}
    },
    bookings: {
        status: IDLE,
        data: []
    },
    bookingDetails: {
        status: IDLE,
        data: {}
    },
    cancelBooking: {
        status: IDLE,
        data: {}
    }
}

const bookingReducer = (state = initialBookingState, { type, data } = {}) => {
    switch (type) {

        case CHECK_BOOK_AVAILABILITY_FETCHING:
            return { ...state, checkAvailability: { status: FETCHING } }
        case CHECK_BOOK_AVAILABILITY_SUCCESS:
            return { ...state, checkAvailability: { status: SUCCESS, data } }
        case CHECK_BOOK_AVAILABILITY_ERROR:
            return { ...state, checkAvailability: { status: ERROR, data } }

        case CONFIRM_BOOKING_FETCHING:
            return { ...state, confirmBooking: { status: FETCHING } }
        case CONFIRM_BOOKING_SUCCESS:
            return { ...state, confirmBooking: { status: SUCCESS, data } }
        case CONFIRM_BOOKING_ERROR:
            return { ...state, confirmBooking: { status: ERROR, data } }

        case LIST_BOOKINGS_FETCHING:
            return { ...state, bookings: { status: FETCHING } }
        case LIST_BOOKINGS_SUCCESS:
            return { ...state, bookings: { status: SUCCESS, data } }
        case LIST_BOOKINGS_ERROR:
            return { ...state, bookings: { status: ERROR, data } }

        case GET_BOOKING_DETAILS_FETCHING:
            return { ...state, bookingDetails: { status: FETCHING } }
        case GET_BOOKING_DETAILS_SUCCESS:
            return { ...state, bookingDetails: { status: SUCCESS, data } }
        case GET_BOOKING_DETAILS_ERROR:
            return { ...state, bookingDetails: { status: ERROR, data } }

        case CANCEL_BOOKING_FETCHING:
            return { ...state, cancelBooking: { status: FETCHING } }
        case CANCEL_BOOKING_SUCCESS:
            return { ...state, cancelBooking: { status: SUCCESS, data } }
        case CANCEL_BOOKING_ERROR:
            return { ...state, cancelBooking: { status: ERROR, data } }

        case RESET_BOOKING_STATUS:
            return {
                ...state,
                checkAvailability: { status: IDLE, data: state.checkAvailability.data },
                confirmBooking: { status: IDLE, data: {} },
                cancelBooking: { status: IDLE, data: {} },
            }

        default:
            return state
    }
}

export default bookingReducer