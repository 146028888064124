import { Modal } from "react-bootstrap";
import Slider from "react-slick";

function PreviewModal(props) {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };
    return (
        <Modal className="images-preview-modal" show={props.show} size="xl" onHide={() => props.onClose()}>
            <Modal.Body>
                <Slider {...settings}>
                    <div>
                        <img src="/assets/images/dormitory-1.jpg" alt="dormitory 1" className="slider-img" />
                    </div>
                    <div>
                        <img src="/assets/images/dormitory-2.jpg" alt="dormitory 1" className="slider-img" />
                    </div>
                    <div>
                        <img src="/assets/images/dormitory-3.jpg" alt="dormitory 1" className="slider-img" />
                    </div>
                    <div>
                        <img src="/assets/images/dormitory-4.jpg" alt="dormitory 1" className="slider-img" />
                    </div>
                </Slider>
            </Modal.Body>
        </Modal>
    )
}

export default PreviewModal