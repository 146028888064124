import Button from 'react-bootstrap/Button'
import './buttons.scss'

function SearchButton({ text, onClick, disabled, isLoading }) {
    return (
        <Button className='search-button' onClick={onClick} disabled={isLoading || disabled}>
            {isLoading ? <div className="spinner-border text-light" role="status" /> : text}
        </Button>
    )
}

export default SearchButton