import { propTypes } from 'react-bootstrap/esm/Image'
import './inputs.scss'

function Input(props) {
    return (
        <div className='form-group input-container'>
            <label className="label">{props.label}</label>
            <input className="input mt-2" id={props.id} name={props.name} type={props.type} value={props.value} placeholder={props.label} onChange={props.onChange} onBlur={props.onBlur} />
            {props.error && <p className="error text-warning">{props.error}</p>}
        </div>
    )
}

export default Input